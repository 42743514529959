<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-date-picker
                v-model="search.date_range"
                type="daterange"
                size="small"
                :picker-options="date_picker_options"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <el-select size="small" @input="handleInputStatusFilter" v-model="status_filter" multiple placeholder="Select">
                <el-option
                  v-for="item in statusFilterOptions"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
                </el-option>
              </el-select> &nbsp;
              <el-select v-model="dateFilterType" size="small">
                <el-option v-for="(item, i) in dateFilterTypeOptions" :key="i" :value="item.value" :label="item.text" />
              </el-select> &nbsp;
              <el-button :loading="loading.table" @click="page = 1; getList();" size="small" class="btn-default" icon="el-icon-search">Search</el-button>
              <!-- <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-primary" icon="el-icon-position">Send to Email</el-button> -->
              <el-button :loading="loading.table" @click="handlerClickDownloadReportBtn();" size="small" class="btn-primary" icon="el-icon-download">Download</el-button>
              <el-button :loading="loading.table" @click="handlerClickDownloadChatlogBtn();" size="small" class="btn-primary" icon="el-icon-download">Report Chatlog</el-button>
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Ticket No</th>
                    <th>Status</th>
                    <th>Customer Name</th>
                    <th>Customer Phone</th>
                    <th>Started At</th>
                    <th>Resolved At</th>
                    <th>Duration</th>
                    <th>Channel</th>
                    <th>Agent Name</th>
                    <th>Group</th>
                    <th>First Response Date</th>
                    <th>First Response Time</th>
                    <th>Labels</th>
                  </tr>
                </thead>
                <tbody v-loading="loading.table">
                  <tr v-for="(item, i) in reportList" :key="i" @click="manage(workspace)" style="cursor: pointer">
                    <td>#{{ item.code }}</td>
                    <td>
                      <span style="padding: 6px" class="badge ml-1" :class="item.status_color_str">{{ upperFirst(item.status) }}</span>
                    </td>
                    <td>
                      {{ item.contacts[0].name }}
                    </td>
                    <td>
                      {{ item.whatsapp_contact ? item.whatsapp_contact.phone_number : item.contacts[0].phone_number }}
                    </td>
                    <td>
                      {{ formatDate(item.opened_at) }}
                    </td>
                    <td>
                      {{ formatDate(item.resolved_at) }}
                    </td>
                    <td>
                      {{ countDuration(item.opened_at, item.resolved_at) }}
                    </td>
                    <td class="text-capitalize">
                      {{ item.channels_used.join(', ') }}
                    </td>
                    <td>
                      {{ item.agent.user ? item.agent.user.name : '-' }}
                    </td>
                    <td>
                      {{ item.agent_group ? item.agent_group.name : '-' }}
                    </td>
                    <td>
                      {{ item.handling_at ? formatDate(item.handling_at) : '-' }}
                    </td>
                    <td>
                      {{ item.handling_at ? countDuration(item.opened_at, item.handling_at) : '-' }}
                    </td>
                    <td>
                      <el-tag size="mini" v-for="(l) in item.labels" :key="l.id" :style="{ 'background-color': l.color, 'color': 'white' }" class="mr-1">
                        {{ l.title }}
                      </el-tag>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="totalRow == 0" description="No data"></el-empty>
            </div>
            <b-pagination v-if="totalRow > page_limit" v-model="currentPage" :total-rows="totalRow" :per-page="page_limit"/>
          </div>
        </div>
      </div>
    </div>

    <b-modal centered v-model="show_modal.downloadReport" :title="this.$t('general.confirmation')" hide-footer @hide="handlerHideModalDownloadReport">
      <p class="mb-2 text-center">
        {{ this.$t('report.confirm_download') }}
        <br>
        You can choose delimiter for your export file.
      </p>
      <b-form>
        <b-form-group class="mb-4 text-center">
          <el-select v-model="delimiter_report" size="small" class="w-30">
            <el-option value=";" label="Delimiter ;" />
            <el-option value="," label="Delimiter ," />
          </el-select>
        </b-form-group>
      </b-form>
      <div class="mt-2 text-center">
        <b-button size="sm" type="submit" @click="confirmDownloadReport" variant="primary" class="mr-2">{{ $t('general.yes') }}</b-button>
        <b-button size="sm" type="reset" @click="handlerHideModalDownloadReport" variant="secondary">{{ $t('general.no') }}</b-button>
      </div>
    </b-modal>

    <b-modal centered v-model="show_modal.downloadChatlog" :title="this.$t('general.confirmation')" hide-footer @hide="handlerHideModalDownloadChatlog">
      <p class="mb-2 text-center">
        {{ this.$t('report.confirm_download') }}
        <br>
        You can choose delimiter for your export file.
      </p>
      <b-form>
        <b-form-group class="mb-4 text-center">
          <el-select v-model="delimiter_chatlog" size="small" class="w-30">
            <el-option value=";" label="Delimiter ;" />
            <el-option value="," label="Delimiter ," />
          </el-select>
        </b-form-group>
      </b-form>
      <div class="mt-2 text-center">
        <b-button size="sm" type="submit" @click="confirmDownloadChatlog" variant="primary" class="mr-2">{{ $t('general.yes') }}</b-button>
        <b-button size="sm" type="reset" @click="handlerHideModalDownloadChatlog" variant="secondary">{{ $t('general.no') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isEmpty, upperFirst, isEqual } from 'lodash';
// import { isEmpty, upperFirst, difference } from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import reportApi from '../../../api/reports';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'HelpdeskReports',
  metaInfo: {
    title: 'Helpdesk Reports',
  },
  data() {
    return {
      upperFirst,
      showModal: false,
      showModalEdit: false,
      page_limit: 50,
      loading: {
        table: false,
      },
      delimiter_chatlog: ';',
      delimiter_report: ';',
      show_modal: {
        downloadChatlog: false,
        downloadReport: false,
      },
      form: {
        name: '',
        ws_product_id: '',
      },
      dateFilterTypeOptions: [
        {
          value: 'open',
          text: 'Open Date',
        },
        {
          value: 'handling',
          text: 'Handling Date',
        },
        {
          value: 'resolved',
          text: 'Resolved Date',
        },
      ],
      dateFilterType: this.$route.query.date_filter_type || 'resolved',
      status_filter: this.$route.query.status_filter || ['resolved'],
      statusFilterOptions: [
        // {
        //   value: 'all',
        //   text: 'All',
        // },
        {
          value: 'open',
          text: 'Open',
        },
        {
          value: 'handling',
          text: 'Handling',
        },
        {
          value: 'resolved',
          text: 'Resolved',
        },
        {
          value: 'unhandled',
          text: 'Unhandled',
        },
      ],
      editedId: '',
      reportList: [],
      loaderStack: 0,
      loader: null,
      search: {
        date_range: [
          this.$route.query.start_date || new Date(),
          this.$route.query.end_date || new Date(),
        ],
      },
      page: this.$route.query.page || 1,
      totalRow: 0,
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 180 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const start = moment().subtract(3, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 6 months',
            onClick(picker) {
              const start = moment().subtract(6, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getList();
      },
    },
    company_id() {
      return this.$store.state.backOffice.activeCompany;
    },
    token() {
      return this.$store.state.backOffice.token;
    },
  },
  async mounted() {
    this.showLoader();
    await this.getList();
    this.hideLoader();
  },
  methods: {
    countDuration(start, end) {
      return start && end ? humanizeDuration(moment(start).diff(moment(end)), { largest: 2, round: true }) : '-';
    },
    formatDate(string, format = 'DD MMM YYYY, HH:mm') {
      return string ? moment(string).format(format) : '-';
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    handlerClickDownloadReportBtn() {
      this.show_modal.downloadReport = true;
      this.delimiter_report = ';';
    },
    handlerHideModalDownloadReport() {
      this.show_modal.downloadReport = false;
    },
    async confirmDownloadReport() {
      this.handlerHideModalDownloadReport();
      this.loading.table = true;
      if (isEmpty(this.search.date_range)) {
        this.search.date_range = [new Date(), new Date()];
      }
      const [start_date, end_date] = this.search.date_range;
      const response = await reportApi.download({
        workspace_id: this.activeWorkspace._id,
        company_id: this.company_id,
        token: this.token,
        start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
        end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
        date_filter_type: this.dateFilterType,
        status_filter: this.status_filter,
        filter_export: 'helpdesk',
        delimiter: this.delimiter_report,
      }).catch(() => {});
      this.loading.table = false;
      await popupErrorMessages(response);
      this.$message({
        title: this.$t('general.success'),
        type: 'success',
        message: this.$t('general.waiting_download'),
        duration: 30 * 1000,
        showClose: true,
      });
    },
    handlerClickDownloadChatlogBtn() {
      this.show_modal.downloadChatlog = true;
      this.delimiter_chatlog = ';';
    },
    handlerHideModalDownloadChatlog() {
      this.show_modal.downloadChatlog = false;
    },
    async confirmDownloadChatlog() {
      this.handlerHideModalDownloadChatlog();
      this.loading.table = true;
      if (isEmpty(this.search.date_range)) {
        this.search.date_range = [new Date(), new Date()];
      }
      const [start_date, end_date] = this.search.date_range;
      const response = await reportApi.downloadChatlog({
        workspace_id: this.activeWorkspace._id,
        company_id: this.company_id,
        token: this.token,
        start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
        end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
        status_filter: this.status_filter,
        date_filter_type: this.dateFilterType,
        filter_export: 'chatlog',
        delimiter: this.delimiter_chatlog,
      }).catch(() => {});
      this.loading.table = false;
      await popupErrorMessages(response);
      this.$message({
        title: this.$t('general.success'),
        type: 'success',
        message: this.$t('general.waiting_download'),
        duration: 30 * 1000,
        showClose: true,
      });
    },
    async getList() {
      this.loading.table = true;
      if (isEmpty(this.search.date_range)) {
        this.search.date_range = [new Date(), new Date()];
      }
      const [start_date, end_date] = this.search.date_range;
      const options = {
        page: this.page,
        start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
        end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
        date_filter_type: this.dateFilterType,
        status_filter: this.status_filter,
      };
      const response = await reportApi.getList({
        workspace_id: this.activeWorkspace._id,
        company_id: this.company_id,
        ...options,
      }).catch(() => {});
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      this.loading.table = false;
      await popupErrorMessages(response);
      const channel_alias = {
        whatsapp: 'WhatsApp',
      };
      this.reportList = response.data.rows.map((item) => {
        item.status_color_str = 'badge-danger';
        if (item.status === 'resolved') {
          item.status_color_str = 'badge-success';
        } else if (item.status === 'handling') {
          item.status_color_str = 'badge-primary';
        }
        item.whatsapp_contact = item.contacts.find((v) => v.channel === 'whatsapp');
        item.channels_used = item.channels.map((v) => channel_alias[v] || upperFirst(v));
        return item;
      });
      this.totalRow = response.data.count;
    },
    handleInputStatusFilter(value) {
      if (value[value.length - 1] === 'all') {
        this.status_filter = ['all'];
      } else if (value[value.length - 1] === 'unhandled') {
        this.status_filter = ['unhandled'];
      } else if (value.indexOf('all') !== -1) {
        this.status_filter.splice(value.indexOf('all'), 1);
      } else if (value.indexOf('unhandled') !== -1) {
        this.status_filter.splice(value.indexOf('unhandled'), 1);
      // } else if (difference(['open', 'handling', 'resolved'], this.status_filter).length === 0) {
      //   this.status_filter = ['all'];
      }
    },
  },
};
</script>
